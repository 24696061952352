.corner-ribbon {
  position: absolute;
  bottom: -1px;
  right: -1px;
  width: 128px;
  height: 128px;
  z-index: 500;

  &.ribbon-128 {
    width: 128px;
    height: 128px;
  }

  &.ribbon-192 {
    width: 192px;
    height: 192px;
  }

  &.ribbon-256 {
    width: 256px;
    height: 256px;
  }
}
