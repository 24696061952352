.legend {
  margin-top: 1em;
  margin-bottom: 0.5em;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  background-color: #fdfdfd;

  span.cirrcle {
    $size: 1.8em;
    background: hsl(0, 0%, 86%);
    border-radius: 50%;
    width: $size;
    height: $size;
    display: inline-block;
    vertical-align: text-bottom;

    // $circle-radius: 1.5em;
    // $line-height: 1.2*$circle-radius;
    // background: hsl(0, 0%, 86%);
    // border-radius: $circle-radius !important;
    // -moz-border-radius: $circle-radius;
    // -webkit-border-radius: $circle-radius;
    // color: #ffffff;
    // display: inline-block;
    // font-weight: bold;
    // line-height: $line-height;
    // // margin-right: 15px;
    // text-align: center;
    // width: $line-height; 
    // font-size: 0.75*$circle-radius;

    $available-color: hsl(141, 71%, 48%);
    &.available {
      background: hsl(141, 71%, 48%);
    }
    &.not-available {
      background: hsl(348, 100%, 61%);
    }
    &.partial-available {
      background: hsl(48, 100%, 67%);
    }
    &.extra-available {
      background: hsl(0, 0%, 84%);
    }
  }
}

table.calendar {
  text-align: center;
  table-layout: fixed;
  width: 100%;
  font-size: 15px;

  tr.week {
    // height: 80px;

  }


  td, th {
    border-color: #eee;
    width: 14.2857%;
    padding: 5px;
  }

  th {
    @extend .has-text-centered;
  }

  // td.day {
  //   background-color: #f79d9b;    
  // }

  // td.day.available {
  //   background-color: #29f706;    
  // }

  $circle-radius: 2.0em;
  span.cirrcle {
    $line-height: 1.2*$circle-radius;
    background: hsl(0, 0%, 86%);
     border-radius: $circle-radius;
    -moz-border-radius: $circle-radius;
    -webkit-border-radius: $circle-radius;
    color: #ffffff;
    display: inline-block;
    font-weight: bold;
    line-height: $line-height;
    // margin-right: 15px;
    text-align: center;
    width: $line-height; 
    font-size: 0.75*$circle-radius;

    $available-color: hsl(141, 71%, 48%);
    &.available {
      background: hsl(141, 71%, 48%);
      &:hover {
        background: hsl(141, 71%, 40%);
        cursor: pointer;
      }
      &.day-selected {
        background: hsl(141, 71%, 30%);
      }
    }
    &.not-available {
      background: hsl(348, 100%, 61%);
    }
    &.partial-available {
      background: hsl(48, 100%, 67%);
    }
    &.extra-available {
      background: hsl(0, 0%, 84%);
    }
  }

  @media (max-width: 600px) {
    /* CSS that should be displayed if width is equal to or less than 800px goes here */
    $circle-radius: 1.65em;

    span.cirrcle {
      $line-height: 1.2*$circle-radius;
      background: hsl(0, 0%, 86%);
       border-radius: $circle-radius;
      -moz-border-radius: $circle-radius;
      -webkit-border-radius: $circle-radius;
      color: #ffffff;
      display: inline-block;
      font-weight: bold;
      line-height: $line-height;
      // margin-right: 15px;
      text-align: center;
      width: $line-height; 
      font-size: 0.75*$circle-radius;

      $available-color: hsl(141, 71%, 48%);
      &.available {
        background: hsl(141, 71%, 48%);
        &:hover {
          background: hsl(141, 71%, 40%);
          cursor: pointer;
        }
        &.day-selected {
          background: hsl(141, 71%, 30%);
        }
      }
      &.not-available {
        background: hsl(348, 100%, 61%);
      }
      &.partial-available {
        background: hsl(48, 100%, 67%);
      }
      &.extra-available {
        background: hsl(0, 0%, 84%);
      }
    }
  }





  td.day.outer span.cirrcle.extra-available {
    background: hsl(0, 0%, 92%);
  }

  td.day.available {
    cursor: pointer;
    &:hover {
      background-color: #b3deb8;
    }
  }
  td.day.available.day-selected {
    background-color: #d4ffd9;
  }

  .cal-event {
    background-color: #ffc107;
    padding-left: 0.6em;
    padding-right: 0.6em;
    border-radius: 0.8rem;
    margin-bottom: 0.5em;
    color: #333;

    .title {
      font-size: 0.85em;
      font-weight: bold;
      line-height: 1.4em;
      text-align: left;

      padding-top: 16px;
    }

    .location {
      font-size: 0.75em;
      text-align: left;
      color: #444;
      padding-top: 1.2em;
      padding-bottom: 1.2em;
    }

    .user {
      margin-left: 0;
      padding-right: -20px;
    }

  }


  td.outer {
    .number,.month-name {
      color: #ddd;
    }
  }
  td.non_workday {
    background-color: #f3f3f3;
  }
  td.holiday {
    background-color: #f3f3f3;
  }
  td.today {
    .number, .month-name {
      color: #e3920c;
      font-weight: bold;
    }
  }

  td.top_line {
    // border-top: 3px solid #aaa;
  }
  td.bottom_line {
    // border-bottom: 3px solid #aaa;
  }
  td.left_line {
    // border-left: 3px solid #aaa;
  }
  td.right_line {
    // border-right: 3px solid #aaa;
  }

  // td.hover {
  //   background-color: red;
  // }

  // td:hover {
  //   @extend .shadow;
  //   cursor: pointer;
  // }

  div.number {
    font-size: 3.2em;
  }
  span.cal-number {
    font-size: 1.4em;
    margin-left: 0.3em;
    margin-right: 0.1em;
  }
  span.month-name {
    font-size: 0.7em;
  }
  td.inner span.day-name {
    color: #bbb;
  }

  td {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  td .dropdown button.dropdown-toggle {
    display: block;
    white-space: normal;
    border: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
    background-color: transparent;
  }
}
