@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
// $size-1: 6rem;

// https://bulma.io/documentation/helpers/typography-helpers/#font-family
$family-primary: "Lato";
$family-secondary: "Caveat";

@charset "utf-8";

@import "bulma";
@import "corner-ribbon";

@import "formValidation";

@import "trix/dist/trix";


@import "bulma-calendar";

// Import component
// @import "bulma-carousel";


section.hero.page-title {
  h1.title {
    font-weight: 900;
    font-size: 2.2em;
  }
  h2.subtitle {
    font-weight: 300;
    font-size: 1.4em;

  }
}

section.hero.page-title.event {
  h1.title {
    font-weight: 900;
    font-size: 2.6em;
  }
  h2.subtitle {
    font-weight: 300;
    font-size: 1.4em;

  }
}


.image.is-160x160 {
  height: 160px;
  width: 160px;
}
.image.is-180x180 {
  height: 180px;
  width: 180px;
}


// click to overlay images
// https://www.sylvaindurand.org/overlay-image-in-pure-css/#_
.overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  visibility: hidden;
  // opacity: 0;
  // transition: opacity .3s;
}

.overlay img{
  max-width: 90%;
  max-height: 90%;

  width: auto;
  height: auto;

  zoom: 10;
  // transform: scale(3);
  // transition: transform .3s;
}

.overlay:target {
  visibility: visible;
  outline: none;
  cursor: default;
}

// .overlay:target img {
//   transform: scale(2);
// }

hr.thin-line {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0))
  // border-top: 0.4px solid #ccc;
  // height: 0;
}

.notification {
  a.no-text-decoration {
    text-decoration: none !important;
  }
}


.has-text-white-transparent-95 {
  color: rgba(255, 255, 255, 0.95) !important;
}
.has-text-white-transparent-90 {
  color: rgba(255, 255, 255, 0.9) !important;
}
.has-text-white-transparent-85 {
  color: rgba(255, 255, 255, 0.85) !important;
}
.has-text-white-transparent-80 {
  color: rgba(255, 255, 255, 0.8) !important;
}
.has-text-white-transparent-75 {
  color: rgba(255, 255, 255, 0.75) !important;
}
.has-text-white-transparent-70 {
  color: rgba(255, 255, 255, 0.7) !important;
}
.has-text-white-transparent-65 {
  color: rgba(255, 255, 255, 0.65) !important;
}
.has-text-white-transparent-60 {
  color: rgba(255, 255, 255, 0.6) !important;
}
.has-text-white-transparent-55 {
  color: rgba(255, 255, 255, 0.55) !important;
}
.has-text-white-transparent-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}



.mandatory-fields-list {
  list-style-type: disc;
  padding-top: 0.5em;

  li {
    line-height: 1.8;
    margin-left: 3em;
  }
}



.participant-data {
  .labelz {
    @extend .has-text-grey;
    @extend .is-size-7;
    margin-bottom: 0;
  }
  .valuez {
    @extend .has-text-black-ter;
    @extend .is-size-6;
    @extend .mb-3;
  }
}


// settings for the two error pages
.hero.is-info.is-bold.error-page {
  &.record-not-found-bg-image {
    background: url(https://unsplash.com/photos/z2t4GdXOtE8/download?force=true&w=1920) no-repeat center center fixed;
  }
  &.not-authorized-bg-image {
    background: url(https://unsplash.com/photos/xit3LjRvKvM/download?force=true&w=1920) no-repeat center center fixed;
  }
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  h1.title {
    @extend .is-size-1;
  }
  .back_to_home {
    margin-top: 3em;
  }
  .photo_credits {
    margin-top: 3.5em;
  }
  a {
    color: #ddd;
    text-decoration: none;
  }
  a:hover {
    color: #fff;
  }
}


// for a sticky footer
body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#wrapper {
  flex: 1;
}

.other_event {
  position: relative;
}


.guest-has-ticket {
  position: absolute;
  top: 0px;
  right: 0px;
}

.guest-has-ticket2 {
  position: absolute;
  top: 20px;
  right: 20px;
  color: rgba(255, 255, 255, 0.6);
}


.guest-ticket {
  background: url("~ticket_04") no-repeat left;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  height: 120px; // definisce la dimensione del ticket
  // display: block;
  // width: 100%;
  // height: 100px;
  // background-image: url("~ticket_02");
  // background-repeat: no-repeat;

  position: relative;

  // .participant-name {
  //   padding-top: 55px;
  //   padding-left: 43px;
  // }

  .participant-name {
    left: 10%;
    bottom: 30%;
    line-height: 1.1;
    position: absolute;
  }

  .ticket-price {
    left: 10%;
    bottom: 10%;
    line-height: 1.1;
    position: absolute;
  }

  .date-n-time {
    border: 1px solid #ccc;
    padding: 3px;
    right: 10%;
    top: 7px;
    position: absolute;
  }


  .alert-ticket {
    top: 0px;
    left: -5px;
    position: absolute;
  }
}

#error_explanation {
  border-left: 3px solid red;
  .message-header {
    padding-left: 1.5em;
  }
  .message-body {
    padding-left: 1.5em;
  }
  ul {
    list-style-type: circle;
    padding-left: 1.5em;
  }
}


.help {
  font-size: 0.9rem;
}

// .show-event {
//   .event-date {
//     @extend .is-uppercase;
//     @extend .mb-5;
//     color: rgba(255, 255, 255, 0.6);
//   }
// }

// .event-tile {
//   .event-date {
//     @extend .is-uppercase;
//     @extend .mb-4;
//     color: rgba(255, 255, 255, 0.6);
//   }

//   .location {
//     @extend .subtitle;
//     color: rgba(255, 255, 255, 0.8);
//   }
// 

.event-tile {
  .title {
    margin-bottom: 1.1rem;
  }
}
.mevent-tile {
  .title {
    margin-bottom: 1.1rem;
  }
}
.show-event {
  .title {
    margin-bottom: 1.1rem;
  }
}


.mandatory:after {
  content: " *"
}

.ticket-card {
  border: 3px solid rgba(0,0,0, 0.3);

  .event-content {
    padding-bottom: 1.5em;
    border-bottom: 1px solid rgba(0,0,0, 0.2);
    margin-bottom: 1.5em;
    display: block;
  }
  .event-date {
    @extend .is-uppercase;
    @extend .mb-4;
    font-size: 0.8em;
    color: rgba(0,0,0, 0.4);
  }
  .event-title {
    font-size: 1.2em;
    font-weight: 600;
    @extend .mb-3;
    @extend .has-text-dark;
  }
  .event-location {
    @extend .has-text-dark;
  }
  .ticket-content {
    display: block;
  }
  .participant-name {
    @extend .has-text-black;
    font-size: 1.2em;
    font-weight: 700;
    @extend .mb-3;
  }
  .ticket-code {
    @extend .has-text-primary;
  }
}



.event-informative {
  ul {
    list-style-type: disc;
    padding-left: 2.5em;
  }
  h1 {
    font-size: 1.6em;
  }
}

#booking-modal {
  z-index: 600;
}
#ticket-modal {
  z-index: 600;
}


#error_explanation {

}




.mandatory-field-tag-mb-0 {
  @extend .tag;
  @extend .is-warning;
  @extend .ml-6;
}

.mandatory-field-tag {
  @extend .mandatory-field-tag-mb-0;
  margin-bottom: 0.8em;
}


.field_with_errors > input {
  @extend .is-danger;
}



.shadow-on-hover {
  transition: box-shadow .1s;
  // cursor: pointer;

  &:hover {
    box-shadow: 0 0 12px rgba(33,33,33,.6);
  }

  a.no-underline {
    text-decoration: none !important;
  }
}

.tile.is-child.shadow-on-hover {
  padding-top: 1.5rem;
  padding-bottom: 2.0rem;
}




@import './calendar.scss';


// bookings/remaining_time
.remaining-time-strip {
  position: fixed;
  top: 3.25em; // 52 px
  width: 100%;
  z-index: 25;
}

.below-remaining-time-strip {
  padding-top: 2.5em;
}


div.navbar-dropdown.is-right {
  z-index: 40;
}

.button.is-text.anchor-1 {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}


@media (max-width: 520px) {
  /* CSS that should be displayed if width is equal to or less than 800px goes here */
  .finish-img {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 521px) {
  /* CSS that should be displayed if width is equal to or less than 800px goes here */
  .finish-img {
    padding-left: 15%;
    padding-right: 15%;
  }
}



tr.no-bottom-border td {
  border-bottom: 0;
}


// patch to buttons with very long text
.button.is-multiline {
  min-height: 2.25em;
  white-space: unset;
  height: auto;
  flex-direction: column;
}
/** This isn't the actual suggested CSS - but it is just a quick fix to replicate the functionality**/
.button.is-multiline .icon {
  margin: 0! important;
}


.trix-content {
  ul {
    list-style-type: disc;
  }
}


@import "./signature_pad.scss";
